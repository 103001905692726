exports.components = {
  "component---src-components-ai-assist-pages-index-js": () => import("./../../../src/components/AIAssistPages/index.js" /* webpackChunkName: "component---src-components-ai-assist-pages-index-js" */),
  "component---src-components-widgetised-pages-index-js": () => import("./../../../src/components/WidgetisedPages/index.js" /* webpackChunkName: "component---src-components-widgetised-pages-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-us-js": () => import("./../../../src/pages/about-us.js" /* webpackChunkName: "component---src-pages-about-us-js" */),
  "component---src-pages-ai-assist-js": () => import("./../../../src/pages/ai-assist.js" /* webpackChunkName: "component---src-pages-ai-assist-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-documents-js": () => import("./../../../src/pages/documents.js" /* webpackChunkName: "component---src-pages-documents-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-languages-js": () => import("./../../../src/pages/languages.js" /* webpackChunkName: "component---src-pages-languages-js" */),
  "component---src-pages-quote-index-js": () => import("./../../../src/pages/quote/index.js" /* webpackChunkName: "component---src-pages-quote-index-js" */),
  "component---src-pages-quote-success-js": () => import("./../../../src/pages/quote/success.js" /* webpackChunkName: "component---src-pages-quote-success-js" */)
}

